"use client";
import { useTranslation } from "@/app/i18n/client";
import BtnMain from "./_components/buttons/BtnMain/BtnMain";
import img from "@assets/images/404.svg";
import Image from "next/image";

export default function NotFound({ lng }: { lng: string }) {
	const { t } = useTranslation(lng);
	return (
		<section className="flex h-[90dvh] flex-col items-center justify-center">
			<Image src={img} alt="404" width={400} height={400} />
			<div className="mt-14 text-center font-secondary">
				<h1 className="text-h5">{t("404.header")}</h1>
				<p className="mt-4 text-base text-gray-700">{t("404.message")}</p>
			</div>
			<BtnMain href="/" label={t("404.cta")} className="mt-12" />
		</section>
	);
}
